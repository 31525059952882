import arrayHelper from './arrayObjectHelper';
import defaults from '../config/fieldDefaults';
import hosts from '../validators/hosts';

const validateOptions = function (userOptions, shouldValidateFields = true) {
  const errors = [];
  const supportedTypes = ['card', 'bank', 'gocardless', 'apple_pay', 'pay_pal', 'direct_debit'];
  const internalTypes = ['stripe_connect_sepa', 'stripe_connect_becs', 'maxio_payments_ach', 'maxio_payments_ach_plaid', 'maxp_ach_plaid'];

  if (!validateSelector(userOptions)) {
    errors.push('`selector` key is required either in the root of the options or on every field. I.e. "#card-form".');
  }

  if (!userOptions.publicKey) {
    errors.push('`publicKey` key is required.');
  }

  if (!supportedTypes.includes(userOptions.type) && !internalTypes.includes(userOptions.type)) {
    errors.push(`\`type\` key is required. Possible values: ${supportedTypes.join(',')}.`);
  }

  if (!['gocardless', 'direct_debit'].includes(userOptions.type)) {
    if (userOptions.selectorForGoCardlessHeader) {
      errors.push('`selectorForGoCardlessHeader` is only supported for type = `gocardless`');
    }

    if (userOptions.selectorForGoCardlessFooter) {
      errors.push('`selectorForGoCardlessFooter` is only supported for type = `gocardless`');
    }

    if (userOptions.selectorForToggleIbanOrLocalDetails) {
      errors.push('`selectorForToggleIbanOrLocalDetails` is only supported for type = `gocardless`');
    }

    if (userOptions.customGoCardlessModalStyles) {
      errors.push('`customGoCardlessModalStyles` is only supported for type = `gocardless`');
    }
  }

  if (userOptions.type === 'gocardless') {
    if (userOptions.addressDropdowns) {
      errors.push('`addressDropdowns` is not supported for type = `gocardless`');
    }
  }

  if (userOptions.type === 'maxio_payments_ach_plaid') {
    if (!userOptions.selectorForPlaidLink) {
      errors.push('`selectorForPlaidLink` key is required.');
    }
  }

  if (userOptions.type === 'maxp_ach_plaid') {
    if (!userOptions.selectorForPlaidLink) {
      errors.push('`selectorForPlaidLink` key is required.');
    }
  }

  if (userOptions.type === 'apple_pay') {
    if (!userOptions.selectorForApplePayButton) {
      errors.push('`selectorForApplePayButton` key is required.');
    }

    if (!userOptions.applePayLabel) {
      errors.push('`applePayLabel` key is required.');
    }

    if (!userOptions.applePayAmount) {
      errors.push('`applePayAmount` key is required.');
    }
  }

  if (userOptions.type === 'pay_pal') {
    if (!userOptions.selectorForPayPalButton) {
      errors.push('`selectorForPayPalButton` key is required.');
    }
  }

  if (userOptions.type && shouldValidateFields && !validateFields(userOptions)) {
    errors.push('One or more of the field names are invalid.');
  }

  if (!userOptions.serverHost) {
    errors.push('`serverHost` key is required.');
  } else if (!hosts.validateServerHost(userOptions.serverHost)) {
    errors.push('`serverHost` key is invalid.');
  }

  if (userOptions.deviceData && typeof (userOptions.deviceData) !== 'boolean') {
    errors.push('`deviceData` option must be a boolean');
  }

  if (shouldValidateFields && userOptions.type && userOptions.fields && Object.keys(userOptions.fields).length > 0) {
    const allFieldsOfGivenType = defaults[userOptions.type];

    const allRequiredFieldNamesOfGivenType = Object.keys(allFieldsOfGivenType).filter((fieldName) => {
      if (userOptions.type === 'gocardless') {
        if ((fieldName === 'phone' || fieldName === 'email') && userOptions.scheme !== 'bacs_nz') {
          return false;
        } else if (fieldName === 'swedishIdentityNumber' && userOptions.scheme !== 'autogiro') {
          return false;
        } else if (fieldName === 'danishIdentityNumber' && userOptions.scheme !== 'betalingsservice') {
          return false;
        }
        return allFieldsOfGivenType[fieldName].required;
      } else if (userOptions.type === 'apple_pay' && ['paymentMethodNonce', 'deviceData'].includes(fieldName)) {
        return false;
      } else if (userOptions.type === 'pay_pal' && ['paymentMethodNonce', 'payPalEmail', 'deviceData'].includes(fieldName)) {
        return false;
      } else if (userOptions.type === 'card' && ['deviceData'].includes(fieldName)) {
        return false;
      }
      return allFieldsOfGivenType[fieldName].required;
    });

    const isValid = allRequiredFieldNamesOfGivenType.every(fieldName => arrayHelper.includes(Object.keys(userOptions.fields), fieldName));

    if (!isValid) {
      errors.push(`We are missing required fields: ${allRequiredFieldNamesOfGivenType.join(', ')}`);
    }
  }

  return { result: errors.length === 0, errors: errors };
};

var validateSelector = function (userOptions) {
  if (userOptions.selector) {
    return true;
  }

  const fields = userOptions.fields;

  if (!fields || !Object.keys(fields).length) {
    return false;
  }

  return Object.keys(fields).every(fieldName => fields[fieldName].selector);
};

var validateFields = function (userOptions) {
  const fields = userOptions.fields;
  const type = userOptions.type;

  if (!fields || !Object.keys(fields).length) {
    return true;
  }

  if (!type) {
    return false;
  }

  return Object.keys(fields).every(fieldName => arrayHelper.includes(Object.keys(defaults[type]), fieldName));
};

export default {
  validateOptions: validateOptions,
  validateSelector: validateSelector,
  validateFields: validateFields,
};
