/* globals Plaid */
import { includeScript } from '../../includeHelper';
import pseudoClassElemStyleHelper from '../../../helpers/pseudoClassElemStyleHelper';

const linkScript = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
let context = {};
let plaidHandler;
const plaidLink = document.createElement('a');
const plaidLinkId = 'chargify-js-plaid-link';

const init = (ctx) => {
  context = ctx;
};

const plaidLinkElem = () => {
  if (!context.globalUserOptions) return;

  return document.querySelector(`${context.globalUserOptions.selectorForPlaidLink}`);
};

const includePlaidScript = async () => {
  if (document.querySelector(`script[src="${linkScript}"]`)) return;

  await includeScript(linkScript);
};

const createIframes = async () => {
  await context.createIframes();
};

const createPlaidLink = () => {
  if (document.querySelector(`#${plaidLinkId}`)) return;

  const plaidLinkName = context.globalUserOptions.plaidLinkName || 'Use Plaid to connect my bank account';

  plaidLink.title = plaidLinkName;
  plaidLink.href = '#';
  plaidLink.id = plaidLinkId;

  const linkName = document.createTextNode(plaidLinkName);
  plaidLink.appendChild(linkName);

  assignCustomStylesForPlaidLink();
  plaidLinkElem().appendChild(plaidLink);
};

const assignCustomStylesForPlaidLink = () => {
  Object.keys(context.globalUserOptions.plaidLinkStyle || {}).forEach((eachStyle) => {
    if (pseudoClassElemStyleHelper.isPseudoClassOrElem(eachStyle)) {
      const cssStyleText = pseudoClassElemStyleHelper.cssStyleTextForElement(
        plaidLink,
        eachStyle,
        context.globalUserOptions.plaidLinkStyle[eachStyle]
      );
      pseudoClassElemStyleHelper.addCssStyleTextToPage(cssStyleText);
    } else {
      plaidLink.style[eachStyle] = context.globalUserOptions.plaidLinkStyle[eachStyle];
    }
  });
};

const showPlaidLinkButton = () => {
  plaidLinkElem().style.display = 'block';
};

const hidePlaidLinkButton = () => {
  plaidLinkElem().style.display = 'none';
};

const maskBankAccountsForOptions = (accounts) =>
  accounts.map(account => {
      return ({
        text: `*** *** ${account.mask}`,
        value: `${account.subtype}|${account.id}`
      });
    });

const setOptionsForSelectField = (options) => {
  const message = {
    action: 'SET_OPTIONS_FOR_SELECT_FIELD',
    data: {
      name: 'bankAccount',
      options
    }
  };

  context.sendMessageToAllFields(message);
};

const initPlaidLink = (token) => {
  plaidHandler = Plaid.create({
    token: token,
    onSuccess: (publicToken, metadata) => {
      const message = {
        action: 'SET_ADDITIONAL_FIELDS_FOR_PLAID',
        data: { publicToken: publicToken, linkSessionId: metadata.link_session_id, bankName: metadata.institution.name }
      }
      context.sendMessageToMainIframe(message);
      context.globalCallbacks.onPlaidComplete();

      setOptionsForSelectField(maskBankAccountsForOptions(metadata.accounts));
      hidePlaidLinkButton();
    },
    onExit: (error) => {
      context.globalCallbacks.onPlaidExit(error ? error.error_message : null);
    },
    onLoad: () => {
      createPlaidLink();
      showPlaidLinkButton();
      plaidLink.addEventListener('click', clickPlaidLink);
    }
  });
};

const clickPlaidLink = () => plaidHandler.open();

const unload = () => {
  if (!plaidLinkElem()) return;

  hidePlaidLinkButton();
};

export default {
  init,
  includePlaidScript,
  createIframes,
  initPlaidLink,
  unload,
};
